<template>
  <section class="edit-category">
    <Loader :loading="showLoader" />
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-title">
            <div class="row">
              <div class="col-12">
                <h3 class="page-title float-left">Add Invoice</h3>

                <router-link class="float-right" to="/settings/invoice-list">
                  <i class="mdi mdi-window-close font-size-18 text-dark"></i>
                </router-link>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <form class="pt-2" @submit.prevent="addInvoice">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-12">

                            <div class="form-group">
                              <label for="name">Name</label>
                              <input type="text" v-model="invoice.name" class="form-control" :class="{
                                'is-invalid':
                                  submitted && $v.invoice.name.$error,
                              }" />
                              <div v-if="
                                submitted && !$v.invoice.name.required
                              " class="invalid-feedback">
                                Name is required
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="form-group">
                              <label for="name">Currency Format</label>
                              <select class="custom-select form-control" v-model="invoice.currency_format">
                                <option selected>Select Currency Format</option>
                                <option value="{SYMBOL}{VALUE}"><span v-if="currencies">{{currencies.symbol}}&nbsp;{{currencies.name}}</span>
                                <span v-else>{VALUE}{SYMBOL}</span></option>
                                <option value="{VALUE}{SYMBOL}"><span v-if="currencies">{{currencies.name}}&nbsp;{{currencies.symbol}}</span>
                                <span v-else>{SYMBOL}{VALUE}</span></option>
                              </select>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="form-group">
                              <label for="name">Image</label>
                              <vue-dropzone ref="myVueDropzone" id="thumbVideo " :options="dropzoneOptions"
                                :useCustomSlot="true" v-on:vdropzone-success="success" @vdropzone-sending="sendImages">
                                <div class="dropzone-custom-content">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="43" height="43" viewBox="0 0 24 24"
                                    fill="transparent" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-upload" color="">
                                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                    <polyline points="17 8 12 3 7 8"></polyline>
                                    <line x1="12" y1="3" x2="12" y2="15"></line>
                                  </svg>
                                  <div class="subtitle">
                                    <span> Drop File or </span>
                                    <span style="color: #eb2027"> Browse</span>
                                  </div>
                                </div>
                              </vue-dropzone>
                            </div>
                          </div>

                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-12">

                            <div class="form-group">
                              <label for="name">Currency Code</label>
                              <multiselect v-model="currencies" :options="currenciesList" track-by="name" label="name">
                              </multiselect>
                            </div>
                          </div>
                          <div class="col-12">

                            <div class="form-group">
                              <label for="name">Currency Symbol</label>
                              <multiselect v-model="currencies" :options="currenciesList" track-by="symbol"
                                label="symbol">
                              </multiselect>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="form-group">
                              <label for="name">Notes</label>
                              <textarea class="form-control" v-model="invoice.notes" rows="5"></textarea>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="form-group mb-2 pb-1">
                              <label for="name" class="pr-2 pt-1">Active</label>
                              <label class="switch">
                                <input type="checkbox" v-model="invoice.active" />
                                <span class="slider round"></span> </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group float-right mb-0  pt-3">
                          <button class="btn btn btn-add btn-primary">Submit</button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
const mediaApiURL = process.env.VUE_APP_MEDIA_SERVICE;
import { setingsApi } from "../../../api";
import Loader from "../../../components/Loader";
import Multiselect from "vue-multiselect";
import { required } from "vuelidate/lib/validators";
export default {
  name: "add-invoice",
  components: {
    Loader,
    Multiselect,
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      showLoader: false,
      invoice: {
        name: "",
        active: true
      },
      options: [],
      dropzoneOptions: {
        url: `${mediaApiURL}/file/upload`,
        maxFilesize: 10,
      },
      currencies: null,
      currenciesList: [],
      submitted: false,
    };
  },
  validations: {
    invoice: {
      name: { required },
    },
  },
  mounted() {
    this.getcurrencies();
  },
  methods: {
    async getcurrencies() {
      this.showLoader = true;
      const { data } = await setingsApi.currencies();
      this.currenciesList = data.data.data;
      this.showLoader = false;
    },
    sendImages(file, xhr, formData) {
      let products = "products";
      xhr.setRequestHeader("Header", "");
      formData.append("folder", products);
    },

    success(file, response) {
      this.invoice.image_id = response.data.id;
      if (response.status == "success") {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: " ",
          text: "Updated successfully",
        });
      } else {
        this.$swal({
          type: "error",
          title: "Oops...",
          text: "The given data was invalid",
        });
      }
    },
    async addInvoice() {

      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      // this.invoice.notes = this.invoice.notes
      if (this.invoice.active === true) {
        this.invoice.active = 1;
      } else {
        this.invoice.active = 0;
      }
      if (this.currencies) {
        this.invoice.currency_code = this.currencies.name,
          this.invoice.currency_symbol = this.currencies.symbol
      }

      this.showLoader = true;
      const data = await setingsApi.addInvoice({
        ...this.invoice,
      });
      this.showLoader = false;

      this.$router.push("/settings/invoice-list");
      if (data.status == 200) {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: " ",
          text: data.messages,
        });
      } else {
        this.$swal({
          type: "error",
          title: "Oops...",
          text: data.messages,
        });
      }
      // alert("SUCCESS!! :-)\n\n" + JSON.stringify(this.invoice));
    },
  },
};
</script>
<style scoped>
.dropzone {
  padding: 29px 20px !important;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css">
</style>
<style src="@riophae/vue-treeselect/dist/vue-treeselect.min.css">
</style>